import { Tokens } from "@kalyzee/kast-web-sdk/http";

export const getTokens = (): Tokens | null => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (!token && !refreshToken) return null;
    return { token: token ?? undefined, refreshToken: refreshToken ?? undefined };
}

export const setTokens = (tokens: Tokens) => {
    if (tokens.token) localStorage.setItem('token', tokens.token);
    if (tokens.refreshToken) localStorage.setItem('refresh_token', tokens.refreshToken);
}

export const removeTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
}