import { KastWebSdkClientHttpInitOptions } from "@kalyzee/kast-web-sdk";

export enum ApiMode {
    Prod,
    Abo,
}

export let mode: ApiMode = ApiMode.Prod;

const getUrl = () => new URL(window.location.href);

const getBackendUrl = () => {
    const url = getUrl();
    const hostNameSplitted = url.hostname.split('.');
    hostNameSplitted.splice(0, 1);
    const hostNameStr = hostNameSplitted.join('.');
    return `${url.protocol}//api.${hostNameStr}`;
};

export const getSdkHttpInitOptions = (): KastWebSdkClientHttpInitOptions => {
    if (mode === ApiMode.Abo) return {
        baseUrl: 'https://abo.dev.kalyzee.com',
        organizationId: '606ee9c4bf11b4001c9ac54b',
    }
    return {
        baseUrl: getBackendUrl(),
    };
}